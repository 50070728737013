$primaryColor: #c91024;
$primaryDarkColor: #e31f2b;
$primaryDarkerColor: #f1434a;
$primaryTextColor: #ffffff;
$primaryFontFamily: "srf";

@import "submodules/ngx_faro_ui_lib/dist/faro/ngx-faro-ui/styles/default_theme";

$fontFamily: "srf";

// Default style corresponds to the PSP theme
:root {
    font-size: 16px;
    letter-spacing: 0.4px;
    font-weight: 400;
    line-height: 24px;
    // Neutral colors
    --color-neutral-0: #ffffff;
    --color-neutral-5: #fafaf8;
    --color-neutral-10: #f5f5f2;
    --color-neutral-15: #ebebe5;
    --color-neutral-20: #dadad2;
    --color-neutral-30: #cac8bf;
    --color-neutral-40: #b9b7ac;
    --color-neutral-45: #918f84;
    --color-neutral-50: #797872;
    --color-neutral-60: #6b6960;
    --color-neutral-70: #4e4d47;
    --color-neutral-80: #22211d;
    --color-neutral-100: #000000;
    --color-warning-main: #ff5549;

    // Secondary colors
    --color-secondary-main: #1a7ac5;
    --color-secondary-light: #1667a7;
    --color-secondary-lighter: #409ce4;
    --color-secondary-dark: #105082;
    --color-secondary-darkest: #033329;
    --color-primary-dialog-button: #1cb373;
    --color-primary-main: #{$primaryDarkColor};
    --color-primary-main-darker: #{$primaryColor};
    --color-primary-contrast: var(--color-neutral-0);
    --font-color-brighter: var(--color-neutral-60);
    --font-color-brightest: var(--color-neutral-50);
    --font-color-dark: var(--color-neutral-70);
    --font-color-darkest: var(--color-neutral-80);
    --font-color-link: var(--color-secondary-main);
    --color-hightlight: #F7A600;

    --cardinality-background-color: #ebebe5;
    --content-background-color: var(--color-neutral-0);
    --date-preset-color: var(--font-color-link);
    --date-preset-background-color: var(--color-neutral-0);
    --panel-header-background-color: var(--color-neutral-15);

    font-family: $primaryFontFamily !important;
    --font-family: $primaryFontFamily !important;

    // Component stylings
    --autocomplete-height: 60px;
    --facet-input-border-radius: 2px;
    --facet-input-height: 40px;
    --facet-input-boder: 1px solid var(--color-neutral-40);
    --facet-header-height: 40px;
    --facet-header-font-size: 16px;
    --facet-header-font-weight: 400;
    --facet-border-radius: 0px;
    --facet-arrow-start-deg: -90deg;
    --facet-arrow-transform: 90deg;
    --date-input-range-border: 1px solid var(--color-neutral-40);
    --date-input-range-invalid-border: 2px solid var(--color-warning-main);
    --date-preset-font-size: 14px;
    --date-preset-font-weight: 400;
    --cardinality-border-radius: 2px;
    --input-background-color: var(--color-neutral-0);
    --input-focus-background-color: var(--color-neutral-0);
    --show-count: block;
    --term-deselect-text-decoration: line-through 2px;
    --zero-border-radius: 0px;
    --button-border-radius: 2px;
    --tooltip-border-radius: 2px;
    --autocomplete-background-color: var(--color-neutral-0);
    --autocomplete-match-color: var(--font-color-dark);
    --input-height: 40px;
    --button-height: 40px;
    --switch-width: 60px;
    --swith-height: 30px;
    --checkbox-height: 18px;
    --checkbox-width: 18px;
    --radio-button-checked: url("../assets/icons/radio-button-checked-srf.svg");
    --button-width: 270px;
    --button-width-medium: 195px;
    --button-width-small: 155px;
    --sidebar-height-correction: calc(var(--header-bar-height) + 261px);
    --scrollpanel-height-correction: calc(var(--header-bar-height) + 260px);
    --content-search-image-margin-bottom: 20px;
    --hitlist-header-height: 60px;
    --hitlist-header-padding: 10px 15px;
    --tab-scroll-height-correction: calc(var(--header-bar-height) + 110px);
    --hitlist-scroll-height: calc(var(--app-height) - var(--header-bar-height) - 60px);
    --tab-scroll-height: calc(var(--app-height) - var(--header-bar-height) - 110px);
    --reset-filter-colapse-height: 50px;
    --side-bar-width: 300px;

    // Header styling
    --header-bar-height: 70px; // set to 0px in embedded mode
    --header-bar-padding: 15px;
    --header-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.16);

    // Search Overlay styling
    --search-overlay-height: 162px;
    --search-overlay-width: 359px;
    --search-overlay-padding: 24px 8px 24px 8px;

    // Dialog styling
    --profile-dialog-width: 900px;
    --order-dialog-width: 600px;
    --dialog-header-height: 64px;
    --profile-dialog-content-height: 646px;
    --order-dialog-content-height: 72px;
    --dialog-footer-height: 72px;
    --dialog-content-background: var(--color-neutral-0);
    --dialog-footer-background: var(--color-neutral-5);

    --tree-scroll-height: 290px;
    --treenode-height: 24px;
    --treenode-line-height: 16px;

    // Datepicker
    --next-prev-icons: var(var(--color-neutral-70));

    // Hitlist styling
    --table-row-height: 62px;
    --table-row-min-height: 62px;
    --table-row-padding: 5px 15px 5px 15px;
    --table-data-padding: 10px 15px;
    --table-head-padding: 10px 15px;
    --table-head-height: 45px;
    --table-hover: rgb(26, 122, 197, 0.2);

    // Player styling
    --player-background-color: rgba(34, 33, 29, 0.9);

    --keyframe-height: 85;

    //shopping-cart
    --shopping-cart-table-scroll-height: calc(100vh - var(--header-bar-height) - 115px);

    --app-height: 100%;
}

// *** FOCUS SHADOW FIXES STYLING ***

.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight:focus,
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight:focus,
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus,
.p-selectbutton .p-button.p-highlight:focus,
.p-selectbutton .p-button:focus,
.p-inputswitch.p-focus .p-inputswitch-slider,
.p-dropdown.p-focus,
.p-datatable .p-sortable-column.p-highlight,
.search-button:focus,
.dialog-secondary-button:focus {
    outline: 2px solid var(--color-neutral-100);
}

/**
Dialog styling
*/

.p-dialog {
    border-radius: var(--zero-border-radius) !important;

    .p-dialog-content {
        background-color: var(--dialog-content-background) !important;
        padding: 0 24px !important;
        border-radius: var(--zero-border-radius) !important;

        .dialog-header {
            height: var(--dialog-header-height) !important;
            color: var(--font-color-darkest) !important;
            border-bottom: 1px solid #dee2e6;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: -24px;
            width: calc(100% + 48px);
            margin-bottom: 16px;
            line-height: 32px;

            .title {
                padding-left: 24px;
                display: flex;
                align-items: center;
                font-weight: 700;
                font-size: 20px;
                letter-spacing: 0;

                img {
                    margin-right: 10px;
                }
            }

            .close-icon {
                border: unset;
                border-radius: 50px;
                background-color: transparent;
                margin-right: 24px;
                width: 32px;
                height: 32px;
                display: flex;
                justify-content: center;
                flex-direction: row;
                align-items: center;
            }

            .close-icon:hover {
                background-color: var(--color-neutral-5);
            }
        }

        .dialog-footer {
            height: var(--dialog-footer-height) !important;
            border-bottom-left-radius: var(--zero-border-radius) !important;
            border-bottom-right-radius: var(--zero-border-radius) !important;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-direction: row;
            background-color: var(--dialog-footer-background);
            margin: 20px -24px 0 -24px;
            padding-right: 24px;
            border-top: 1px solid #dee2e6;
            position: fixed;
            bottom: 0;
            width: 100%;

            .search-button {
                margin-right: 8px;
            }

            .dialog-secondary-button {
                background-color: var(--color-neutral-15);
                border: unset;
                color: var(--font-color-dark);
                font-weight: 600;
            }

            .dialog-secondary-button:not(:disabled):hover {
                background-color: var(--color-neutral-20);
                color: var(--font-color-dark);
            }
        }
    }

    .p-dialog-header {
        height: var(--dialog-header-height) !important;
        color: var(--font-color-darkest) !important;
        border-top-left-radius: var(--zero-border-radius) !important;
        border-top-right-radius: var(--zero-border-radius) !important;

        .p-dialog-header-icon {
            color: var(--color-neutral-70);
        }

        .p-dialog-header-icon:hover {
            background-color: var(--color-neutral-10) !important;
        }
    }
}

.profile-dialog {
    width: var(--profile-dialog-width) !important;

    .p-dialog-content {
        height: var(--profile-dialog-content-height) !important;

        .dialog-content {
            .profile-dialog-main {
                display: flex;
                justify-content: space-between;
                border-radius: var(--zero-border-radius) !important;

                .dialog-options {
                    width: 414px;
                    background-color: var(--color-neutral-0);
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0;
                    box-shadow: inset 0 1px 0 #ebebe5;

                    .dialog-options-entries {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 10px 16px 0;
                        width: 100%;
                        justify-content: space-between;

                        .checkbox-label {
                            color: var(--color-neutral-80);
                        }
                    }

                    .dialog-options-entries:last-child {
                        padding: 10px 16px 0;
                    }
                }
            }
        }
    }
}

.order-dialog {
    width: var(--order-dialog-width) !important;
}

.music-dialog {
    width: 80%;

    .p-dialog-content {
        margin-bottom: unset;
    }
}

.export-dialog {
    width: var(--order-dialog-width) !important;
}

.restriction-dialog {
    .p-dialog-content {
        padding-top: 0 !important;
    }
}

.sync-table-border {
    box-sizing: border-box;
    position: absolute;
    left: 5px;
    right: 5px;
    top: 5px;
    bottom: 5px;
    border: 2px solid var(--color-secondary-main);
    border-radius: 4px;
}

.loading-tree {
    height: var(--tree-scroll-height) !important;
}

.splitter {
    height: calc(100vh - 55px - var(--header-bar-height));
    height: calc(var(--app-height) - 55px - var(--header-bar-height));
}

.splitter > .p-splitter-panel-nested {
    width: 0;
    min-width: 500px;
    max-width: calc(100vw - 445px);
}

.p-splitter {
    border: unset;
}

.vertical-splitter > .p-splitter-panel-nested {
    height: 0;
}

.p-splitter .p-splitter-gutter {
    background: var(--color-neutral-50);
}

.p-splitter .p-splitter-gutter:hover {
    background: var(--color-neutral-60);
}

.p-splitter .p-splitter-gutter:active {
    background: var(--color-neutral-60);
}


.p-splitter .p-splitter-gutter:hover {
    background: var(--color-neutral-60);
}

.p-splitter .p-splitter-gutter:active {
    background: var(--color-neutral-60);
}

.p-splitter-horizontal > .p-splitter-gutter > .p-splitter-gutter-handle,
.p-splitter-vertical > .p-splitter-gutter > .p-splitter-gutter-handle {
    background: var(--color-neutral-70);
}

.p-splitter-vertical > .p-splitter-gutter > .p-splitter-gutter-handle {
    height: 2px !important;
}

.p-splitter-horizontal > .p-splitter-gutter > .p-splitter-gutter-handle {
    width: 2px !important;
}

.p-tabview .p-tabview-panels,
.information-container {
    padding: 0;
    height: calc(100vh - var(--tab-scroll-height-correction));
    height: calc(var(--app-height) - var(--tab-scroll-height-correction));
}

.p-tabview-nav {
    margin-left: 16px !important;
}

#calendarPanel {
    .p-disabled,
    .p-component:disabled {
        opacity: 0.2 !important;
    }
}

td {
    img {
        border-radius: 2px;
    }
}

.p-datepicker table td > span.p-disabled {
    opacity: 0.2 !important;
}

.p-datepicker .p-monthpicker .p-monthpicker-month,
.p-datepicker .p-yearpicker .p-yearpicker-year {
    border-radius: 50px;
}

.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight:hover,
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight:hover,
.p-datepicker table td > span.p-highlight:hover {
    background-color: var(--color-secondary-light);
    color: var(--color-neutral-0);
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:hover {
    color: var(--color-secondary-main) !important;
}

input {
    padding: 1px 2px !important;
    color: var(--color-neutral-60) !important;
}

input::placeholder {
    color: var(--color-neutral-40);
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link,
.p-tabview .p-tabview-nav {
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 0;
    border-right-color: transparent;
    border-right-style: solid;
    border-right-width: 0;
    border-bottom-color: transparent;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-left-color: transparent;
    border-left-style: solid;
    border-left-width: 0;
    font-size: 14px;
    line-height: 20px;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:focus {
    box-shadow: unset !important;
    border-color: var(--color-neutral-40);
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link:focus {
    border-color: var(--color-primary-main-darker);
}

.shopping-cart-hit-list-dropdown > .p-dropdown:hover,
.shopping-cart-hit-list-dropdown > .p-dropdown.p-focus,
.hit-list-dropdown > .p-dropdown:hover,
.hit-list-dropdown > .p-dropdown.p-focus {
    border-color: var(--color-neutral-50);
}

.shopping-cart-hit-list-dropdown > .p-dropdown {
    width: calc(100% + 7px);
    border-radius: var(--facet-input-border-radius);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: var(--color-neutral-0) !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight:hover {
    background-color: var(--color-secondary-light) !important;
}

.p-dropdown-item.p-focus:not(.p-highlight) {
    background-color: var(--color-neutral-10) !important;
}

.p-dropdown-item:hover:not(.p-highlight) {
    background-color: var(--color-neutral-10) !important;
}


.range-field-container > .range-field > .range,
.search-field-container > .input-field > input,
.search-field-container > .search-field > .input-field > input,
.shopping-cart-hit-list-dropdown > .p-dropdown .p-dropdown-label,
.hit-list-dropdown > .p-dropdown .p-dropdown-label {
    font-family: "srf", sans-serif !important;
    letter-spacing: 0.4px;
}

.hit-list-dropdown > .p-dropdown .p-dropdown-label {
    color: var(--color-neutral-80);
    padding-left: 10px;
}

.shopping-cart-hit-list-dropdown > .p-dropdown .p-dropdown-label,
.shopping-cart-hit-list-dropdown > .p-dropdown > .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    color: var(--color-neutral-60);
    padding-left: 10px;
}

.no-value-selected > .p-dropdown .p-dropdown-label,
.shopping-cart-hit-list-dropdown > .p-dropdown .p-dropdown-label.p-placeholder {
    color: var(--color-neutral-40) !important;
}

.shopping-cart-hit-list-dropdown > .p-dropdown > .p-dropdown-panel .p-dropdown-items .p-dropdown-item:hover,
.hit-list-dropdown > .p-dropdown > .p-dropdown-panel .p-dropdown-items .p-dropdown-item:hover {
    background-color: var(--color-neutral-5);
}

.range-field-container,
.panel > .content > .search-field-container {
    padding: 10px 10px 5px 10px !important;
}

.range-field:focus-within,
.range-field:hover {
    border-color: var(--color-neutral-50) !important;
}

.range-field {
    &.pending {
        border: var(--date-input-range-border) !important;
    }

    &.invalid {
        border: var(--date-input-range-invalid-border) !important;
    }
}

.option-count {
    padding-left: 4px;
    padding-right: 4px;
    min-width: 16px !important;
    width: unset !important;
}

.detail-header {
    app-breadcrumbs {
        .breadcrumb-header {
            .breadcrumbs {
                .breadcrumb {
                    color: var(--color-neutral-60) !important;
                }
            }
        }
    }

    .page-indicator {
        color: var(--color-neutral-60);
    }

    .paginator-button:hover {
        background-color: var(--color-neutral-15) !important;
    }
}

.shopping-cart-header {
    app-breadcrumbs {
        .breadcrumb-header {
            .breadcrumbs {
                .breadcrumb {
                    color: var(--color-neutral-0) !important;
                }
            }
        }
    }

    .page-indicator {
        color: var(--color-neutral-0);
    }

    .paginator-button:hover {
        background-color: var(--color-secondary-light) !important;
    }
}

.secondary-button {
    width: var(--button-width);
    color: var(--color-neutral-70);
    background-color: var(--color-neutral-15);
    border: unset;
    border: unset;
    font-size: 16px;
    display: flex;
    height: 40px;
    justify-content: center;
    flex-direction: row;
    border-radius: 2px;
    align-items: center;
    font-weight: 600;

    img {
        margin-right: 5px;
    }
}

.secondary-button:not(:disabled):hover {
    color: var(--color-neutral-70) !important;
    background-color: var(--color-neutral-20) !important;
}

.secondary-button:focus {
    background-color: var(--color-neutral-20) !important;
    color: var(--font-color-dark) !important;
}

.secondary-button,
.search-button,
.dialog-secondary-button {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.16) !important;
}

.p-checkbox .p-checkbox-box {
    border: 1px solid var(--color-neutral-50) !important;
    border-radius: var(--zero-border-radius) !important;
    height: var(--checkbox-height) !important;
    width: var(--checkbox-width) !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--color-secondary-main) !important;
    background: #ffffff;
}

.p-checkbox .p-checkbox-box.p-highlight:hover {
    background-color: transparent !important;
}

.p-checkbox .p-checkbox-box.p-focus,
.p-checkbox .p-checkbox-box:hover {
    box-shadow: none !important;
    border: 2px solid var(--color-secondary-main) !important;
}

.item p-triStateCheckbox {
    height: 24px;
    width: 18px;
    flex-direction: column;
    justify-content: center;
    display: flex;
}

.p-inputtext:focus {
    box-shadow: none !important;
}

.p-datatable .p-datatable-tbody > tr {
    height: var(--table-row-height);
}

.p-datatable .p-datatable-tbody > tr > td {
    padding: var(--table-data-padding);
    //max-width: unset;
}

.p-datatable .p-datatable-thead > tr > th {
    height: var(--table-head-height);
    padding: var(--table-head-padding);
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.4px;
    font-weight: 400;
}

.p-datatable-table {
    border-collapse: collapse;
}

.music-table {
    .p-datatable-tbody > tr {
        background-color: var(--color-neutral-0) !important;
        padding: unset !important;
        cursor: auto;
    }

    .p-datatable-thead > tr > th {
        background-color: var(--color-neutral-0) !important;
    }
}

.p-table-icon-button {
    background-color: transparent;
    color: var(--color-neutral-70);
    border: unset;
}

.p-table-icon-button:focus,
.p-table-icon-button:hover {
    background-color: transparent !important;
    color: var(--color-neutral-70);
}

.p-table-action-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.action-header {
    width: 55px;
    padding-right: 15px !important;
}

.p-datatable .p-datatable-tbody > tr > td:last-child {
    padding-right: 0 !important;
}

.media-column,
.hit-list-image {
    position: relative;

    .table-entry-number {
        position: absolute;
        top: 10px;
        color: var(--color-neutral-0);
        background-color: var(--color-secondary-main);
        padding: 0 10px;
        border-radius: 2px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }
}

.action-icon-td {
    justify-content: flex-end;
    width: 40px;
    text-align: right !important;
    padding-left: 0 !important;
}

tr:hover > .p-frozen-column {
    background: transparent !important;
}

.p-table-action-button-container {
    width: 82px;
    display: flex;
    z-index: 99;
    position: absolute;
    right: 10px;
    top: 10px;
    justify-content: flex-end;
    flex-wrap: wrap;

    .p-table-action-button {
        background-color: var(--color-secondary-main) !important;
        color: var(--color-neutral-0) !important;
        border: unset;
        border-radius: var(--button-border-radius);
        width: 36px !important;
        height: 36px !important;
    }

    .break {
        flex-basis: 100%;
        height: 0;
        margin-top: 10px;
    }

    .p-table-action-button:focus {
        background: var(--color-secondary-main);
        color: var(--color-neutral-0);
    }

    .p-table-action-button:hover {
        background: var(--color-secondary-light) !important;
        color: var(--color-neutral-0);
    }
}

faro-dialog-panel {
    .dialog-panel {
        .header {
            margin: unset !important;
        }
    }
}

.tree-select {
    .container {
        .checkBox {
            justify-content: unset !important;
            padding-left: 15px !important;
            padding-bottom: 10px;
            margin-top: 15px;
            border-bottom: 1px solid var(--color-neutral-15);
        }
    }
}

.p-table-action-button-container > .p-table-action-button:first-child {
    margin-right: 10px;
}

.table-row {
    cursor: pointer;
    min-height: var(--table-row-min-height);
    position: relative;
}

.active-sequence-transcript-subtitle {
    border-radius: 4px;
    box-shadow: inset 0 0 0 2px var(--color-secondary-main);
}

.p-datatable-scrollable .p-datatable-tbody > tr > td {
    align-items: unset !important;
    flex: 1 1 0 !important;
    text-align: left;
    word-break: break-word;
}

.p-datatable-scrollable .p-frozen-column {
    background: unset;
}

.presets-container {
    padding: 0 10px !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5px;

    .preset {
        padding: 0 !important;
        width: 50% !important;

        button {
            height: 24px !important;
            padding: 0 !important;
            border: 0;
            border-radius: 2px !important;
            color: var(--date-preset-color);
            background-color: var(--date-preset-background-color);
            font-size: var(--date-preset-font-size);
            font-weight: var(--date-preset-font-weight);
            cursor: pointer;
            margin-left: 5px;
        }
    }

    .preset:hover {
        background-color: var(--color-neutral-5) !important;

        button {
            background-color: var(--color-neutral-5) !important;
        }
    }
}

.shopping-cart-content-header,
.hitlist-header,
.shopping-cart-hitlist-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: var(--hitlist-header-height);
    align-items: center;
    padding: var(--hitlist-header-padding);
}

.hitlist-header .hitlist-header-title,
.shopping-cart-hitlist-header .shopping-cart-hitlist-header-title,
.shopping-cart-content-header .shopping-cart-content-header-title {
    font-weight: 700;
    font-size: 16px;
    color: var(--color-neutral-80);
    margin: 0;
}

// *** TREE STYLING ***
.p-tree {
    height: var(--tree-scroll-height);
    padding-left: 8px;
    padding-top: 8px;
}

.p-treenode-label {
    line-height: var(--treenode-line-height) !important;
}

.p-tree-container {
    overflow: hidden !important;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
    height: var(--treenode-height) !important;
}

.p-treenode-content::before {
    display: none;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
    margin-right: 0;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
    background-color: var(--color-neutral-15) !important;
}

.export-dialog-content {
    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
        font-weight: 700;
    }
}

.divider {
    height: 16px;
}

.panel > .header > button {
    height: 24px;
    align-items: center;
    display: flex;
}

.panel > .header > button:hover {
    color: var(--color-secondary-main) !important;
}

.panel > .content {
    .hint {
        color: var(--font-color-brightest);
        font-weight: 400;
        margin: 8px 10px;
    }
}

.search-select > .p-selectbutton .p-button,
.facet-select-button > .p-selectbutton .p-button {
    background-color: var(--color-neutral-15);
}

.search-select > .p-selectbutton .p-button.p-highlight,
.facet-select-button > .p-selectbutton .p-button.p-highlight {
    background-color: var(--color-neutral-30);
}

.search-select > .p-selectbutton > .p-button:hover,
.facet-select-button > .p-selectbutton > .p-button:hover {
    background-color: var(--color-neutral-30) !important;
}

.search-select > .p-selectbutton > .p-button.p-highlight:hover,
.facet-select-button > .p-selectbutton > .p-button.p-highlight:hover {
    background-color: var(--color-neutral-40) !important;
}

.search-select > .p-selectbutton .p-button {
    border-radius: 0;
}

.p-selectbutton .p-button:first-of-type {
    border-radius: var(--button-border-radius) 0 0 var(--button-border-radius);
}

.p-selectbutton .p-button:last-of-type {
    border-radius: 0 var(--button-border-radius) var(--button-border-radius) 0;
}

.hit-list-select > .p-selectbutton > .p-button:hover {
    background-color: var(--color-neutral-15) !important;
}

.hit-list-select > .p-selectbutton > .p-button.p-highlight:hover {
    background-color: var(--color-neutral-20) !important;
}

.p-toast {
    opacity: 1 !important;
}

.p-toast-top-center {
    top: 90px !important;
    left: calc(50% - 155px) !important;
    transform: translate(50%);
}

.p-toast .p-toast-message {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.08), 0 2px 4px rgba(0, 0, 0, 0.16);
}

.p-toast .p-toast-message.p-toast-message-error,
.p-toast .p-toast-message.p-toast-message-success,
.p-toast .p-toast-message.p-toast-message-info {
    background: var(--color-neutral-0);
    color: var(--color-neutral-70);
    border-radius: 2px;
}

.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
    color: #ffbbbc !important;
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
    color: #1cb373 !important;
}

.pi-search:before {
    content: url("../assets/icons/search.svg") !important;
}

.pi-search {
    &.highlight::before {
        content: url("../assets/icons/search-highlighted.svg") !important;
    }
}

.p-dialog-header-icon > .pi-times:before {
    content: url("../assets/icons/close-icon.svg") !important;
}

.p-dialog .p-dialog-content .dialog-footer .dialog-secondary-button > .pi-times:before {
    content: url("../assets/icons/cancel-icon.svg") !important;
}

.search-button > .pi-check:before,
.p-menuitem > .p-menuitem-link > .pi-check:before {
    content: url("../assets/icons/check-icon.svg") !important;
}

.p-checkbox .p-checkbox-box.p-highlight > .pi-times:before {
    content: url("../assets/icons/checkbox-cross.svg") !important;
}

.p-checkbox .p-checkbox-box.p-highlight > .pi-check:before {
    content: url("../assets/icons/checkbox-check.svg") !important;
}

.p-checkbox .p-checkbox-box.p-highlight > .pi-minus:before {
    content: url("../assets/icons/checkbox-line.svg") !important;
}

.p-checkbox .p-checkbox-box.p-indeterminate > .pi-minus:before {
    content: url("../assets/icons/checkbox-line.svg") !important;
}

.reorder-icon > .reorder-table-icon:before {
    content: url("../assets/icons/reorder.svg") !important;
}

.p-checkbox-icon {
    color: var(--color-secondary-main) !important;
}

// *** TOOLTIP STYLING ***

.table-tooltip {
    .p-tooltip-text {
        width: 653px !important;
        padding: 8px !important;
        max-height: 600px;
    }

    .common_highlight {
        color: var(--color-neutral-80);
    }
}

.p-tooltip-arrow {
    display: none !important;
}

.p-tooltip-text {
    color: var(--color-neutral-0) !important;
    background-color: var(--color-neutral-45) !important;
    border-radius: var(--tooltip-border-radius) !important;
}

.export-target-tooltip,
.entries-table-tooltip {
    max-width: unset;
}

/**
* Spinner styling
*/
.p-progress-spinner {
    height: 20px !important;
    width: 20px !important;
}

.custom-spinner .p-progress-spinner-circle {
    animation: custom-progress-spinner-dash 1.5s ease-in-out infinite,
        custom-progress-spinner-color 5s ease-in-out infinite;
    stroke-width: 4px;
}

.pi-trash::before {
    content: url("/assets/icons/trash.svg") !important;
}

.pi-trash {
    &.highlight::before {
        content: url("/assets/icons/trash-highlighted.svg") !important;
    }
}

.search-button > .p-button-icon.p-button-icon-left.pi.pi-search::before {
    content: url("/assets/icons/search-white.svg") !important;
}

.search-button > .p-button-icon.p-button-icon-left.pi.pi-sync::before {
    content: url("/assets/icons/sync-white.svg") !important;
}

.pi-times-circle::before {
    content: url("/assets/icons/warning-icon.svg") !important;
}

.p-tree-toggler .pi-chevron-right:before {
    content: url("/assets/icons/chevron-right.svg");
    transform: rotate(90deg) !important;
}

.p-tree-toggler .pi-chevron-down:before {
    content: url("/assets/icons/chevron-down.svg");
    transform: rotate(90deg) !important;
}

.p-dropdown .p-dropdown-trigger .pi-chevron-down {
    height: 22px;
}

.p-dropdown .p-dropdown-trigger .pi-chevron-down:before {
    content: url("/assets/icons/dropdown-chevron-down.svg");
}

.pi-chevron-left:before {
    content: url("/assets/icons/chevron-left.svg");
}

.pi-chevron-right:before {
    content: url("/assets/icons/chevron-right.svg");
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon:before {
    content: url("/assets/icons/check-green.svg") !important;
}

.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon:before {
    content: url("/assets/icons/info.svg") !important;
}

.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon:before {
    content: url("/assets/icons/warning-icon.svg") !important;
}

.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon .p-icon-wrapper,
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon .p-icon-wrapper {
    display: none;
}

.p-toast .p-toast-message.p-toast-message-error .p-toast-message-content .p-toast-summary {
    color: var(--color-primary-main);
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-content .p-toast-summary,
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-content .p-toast-summary {
    color: var(--color-neutral-70);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    content: url("/assets/icons/switch-checked.svg");
    display: flex;
    justify-content: center;
}

.p-inputswitch .p-inputswitch-slider:before {
    content: url("/assets/icons/switch-unchecked.svg");
    display: flex;
    justify-content: center;
}

.p-toast .p-toast-message.p-toast-message-info {
    border: unset;
}

.pi-calendar {
    width: 24px;
}

faro-list-facet > faro-facet-panel > .panel > .content > div > .list {
    max-height: 204px;
    overflow-y: scroll;
}

faro-inline-edit-field {
    input:focus {
        border-color: var(--color-neutral-40) !important;
    }

    input:hover {
        border-color: var(--color-neutral-50) !important;
    }
}

.info-group {
    faro-inline-edit-field {
        .display-string {
            margin-bottom: 24px;

            .edit-button {
                height: 24px !important;
            }
        }
    }
}

td,
.info-group {
    faro-inline-edit-field {
        .display-string {
            align-items: flex-start;

            .display-string-entry {
                font-size: 16px !important;
                font-weight: 400;
                color: var(--color-neutral-80);
            }

            .empty-string {
                color: var(--color-neutral-60);
            }

            .edit-button {
                img {
                    margin-top: 2px;
                }
            }
        }

        input {
            width: 100%;
            height: var(--facet-input-height);
            border-radius: var(--facet-input-border-radius) !important;
            border: var(--facet-input-boder);
            color: var(--color-neutral-60) !important;
        }

        .inline-input-field {
            font-weight: 400;
            font-size: 16px;
            color: var(--color-neutral-80) !important;
            padding: 0 10px !important;
        }
    }
}

.remark-column {
    faro-inline-edit-field {
        display: block;
        height: 100%;

        .display-string {
            height: 100% !important;
        }
    }
}

.detail-header .right-actions,
.shopping-cart-header .right-actions {
    display: flex;
    align-items: center;

    .close-button,
    .print-button {
        background-color: transparent;
        border: unset;
        border-radius: var(--button-border-radius);
        width: 24px !important;
        height: 24px !important;
        cursor: pointer;
        padding: unset;
        margin-left: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .print-button-placeholder {
        width: 24px !important;
        height: 24px !important;
        margin-left: 26px;
    }
}

.detail-header .right-actions {
    .close-button:hover,
    .print-button:hover {
        background-color: var(--color-neutral-15);
    }
}

.panel .content > .search-field-container > .search-field > .input-field {
    width: 250px !important;
}

.search-field-container {
    .search-field {
        width: unset !important;
        padding: unset !important;
        border: unset !important;
    }

    .input-field {
        width: 270px;
        height: var(--facet-input-height);
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: var(--facet-input-border-radius);
        border: var(--facet-input-boder);
        background-color: var(--input-background-color);
        padding: 0 10px;

        &.invalid {
            border: var(--date-input-range-invalid-border) !important;
        }

        input {
            width: 100%;
            height: 100%;
            margin-right: 14px;
            color: var(--font-color-brightest);
            background-color: transparent;
            border: 0;
            outline: 0;
            font-size: 16px;
            font-weight: 400;

            &:focus {
                outline: 0 !important;
            }
        }

        button {
            border: 0;
            padding: 0;
            color: var(--font-color-dark, #b0b0b0);
            background-color: transparent;
            cursor: pointer;

            i {
                font-size: 12px;
            }
        }
    }

    .input-field:focus-within {
        border-color: var(--color-neutral-50) !important;

        &.invalid {
            border: var(--date-input-range-invalid-border) !important;
        }
    }

    .input-field:hover {
        border-color: var(--color-neutral-50) !important;

        &.invalid {
            border: var(--date-input-range-invalid-border) !important;
        }
    }
}

.presets-container > .preset > button:focus {
    outline: unset;
    box-shadow: unset;
    text-decoration: underline;
}

.search-button {
    background-color: var(--color-primary-main);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.16) !important;
    border: unset;
    font-size: 16px;
    display: flex;
    height: 40px;
    justify-content: center;
    flex-direction: row;
    border-radius: 2px;
    align-items: center;
    color: white;
    font-weight: 600;
    padding: 0.5rem 1rem;

    img {
        margin-right: 5px;
    }
}

.search-button:hover {
    background-color: var(--color-primary-main-darker) !important;
}

.search-button:focus {
    background-color: var(--color-primary-main-darker) !important;
}

.date-facet-overlay-panel {
    top: 331px !important;
}

.term-deselected {
    opacity: 0.2;
    text-decoration: unset !important;
}

.no-details-content {
    padding: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 70vh;

    .no-details-text-title {
        color: var(--color-primary-main);
        font-size: 16px;
        font-weight: 700;
        display: flex;
    }

    .no-details-warning-icon {
        margin-top: 2px;
        margin-right: 16px;
    }
}

app-filters {
    .side-bar {
        height: 100%;
        width: 299px;

        .search-actions {
            padding: 10px;
            display: flex;
            justify-content: center;
            width: 299px;
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.08), 0 2px 4px rgba(0, 0, 0, 0.16);

            .search-button-container {
                .search-button {
                    width: var(--button-width);
                }

                .filters-set {
                    margin-top: 10px;
                }
            }
        }
    }
}

/**
Shopping cart view
*/

.shopping-cart-preview,
.search-shopping-cart-view,
.shopping-cart-view {
    display: flex;
    height: calc(100vh - var(--header-bar-height) - 50px);
    height: calc(var(--app-height) - var(--header-bar-height) - 50px);
    .side-bar {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 300px;
        border-right: 1px solid var(--color-neutral-20);

        .shopping-cart-actions {
            padding: 10px;
            display: flex;
            justify-content: center;
            position: absolute;
            bottom: 0;
            width: 300px;
            z-index: 999;
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.08), 0 2px 4px rgba(0, 0, 0, 0.16);
            background-color: var(--color-neutral-0);

            .search-button-container {
                .search-button {
                    width: var(--button-width);
                }
            }
        }
    }
}

.active-shopping-cart-details {
    .shopping-cart-name {
        faro-inline-edit-field {
            .display-string {
                align-items: center;
                overflow-wrap: anywhere;

                .display-string-entry {
                    font-size: 20px;
                    font-weight: 700;
                    color: var(--color-neutral-80);
                }

                .empty-string {
                    color: var(--color-neutral-80);
                }

                .edit-button {
                    align-items: center;
                }
            }
        }

        .inline-input-field {
            font-weight: 700;
            font-size: 20px;
            color: var(--color-neutral-80) !important;
            padding: 0 10px !important;
        }
    }
}

.shopping-cart-header .right-actions {
    .close-button:hover,
    .print-button:hover {
        background-color: var(--color-secondary-light);
    }
}

.active-shopping-cart-details .input-field-container {
    margin-top: 0;
}

.shopping-cart-hit-list-dropdown > .p-dropdown > .p-dropdown-panel > .p-dropdown-items-wrapper {
    max-height: unset !important;
}

.active-shopping-cart-details .input-field-container,
.shopping-cart-input-field {
    margin-top: 10px;

    label {
        font-size: 14px;
        color: var(--color-neutral-70);
    }

    .input-field {
        display: flex;
        position: relative;
        align-items: center;
        width: 270px !important;
    }

    input {
        width: 100%;
        height: var(--facet-input-height);
        border-radius: var(--facet-input-border-radius);
        border: var(--facet-input-boder);
        color: var(--color-neutral-60) !important;
    }

    input:focus {
        border-color: var(--color-neutral-40) !important;
    }

    input:hover {
        border-color: var(--color-neutral-50) !important;
    }
}

faro-inline-edit-field {
    .input-field-container {
        margin-top: 0 !important;
    }
}

.p-datatable-dragpoint-bottom {
    box-shadow: inset 0 -2px 0 0 var(--color-secondary-main) !important;

    td {
        box-shadow: unset !important;
    }
}

.p-datatable-dragpoint-top {
    box-shadow: inset 0 2px 0 0 var(--color-secondary-main) !important;

    td {
        box-shadow: unset !important;
    }
}


/**
Player styling
*/

.fp-footer,
.fp-header,
.fp-togglable {
    display: none;
}

.flow-player43 {
    display: flex !important;
    justify-content: center;
    align-items: center;
    top: unset;

    video.fp-engine {
        position: relative;
        object-fit: fill;
        left: unset;
        top: unset;
        aspect-ratio: 4 / 3;
        max-width: 100%;
        max-height: 100%;
    }
}

.fp-middle {
    z-index: 999;

    .fp-switch {
        margin-top: 5px;
    }
}

.chart-progress-bar {
    .p-slider,
    .p-slider-range {
        background: transparent;
    }

    .p-slider.p-slider-horizontal {
        width: 100%;
        height: 85%;
        cursor: pointer;
    }

    .p-slider-horizontal .p-slider-handle {
        top: unset;
    }

    .p-slider-handle {
        background-color: var(--color-neutral-0);
        border: 3px solid var(--color-secondary-main);
        width: 8px;
        height: 100%;
        border-radius: 4px;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.32);
        margin-left: -4px !important;
        margin-top: 0 !important;
    }

    .p-slider-handle:focus {
        box-shadow: 0 0 4px var(--color-secondary-main);
    }

    .p-slider-handle:active,
    .p-slider-handle:hover {
        background: var(--color-neutral-0);
        box-shadow: 0 0 4px var(--color-secondary-main);
    }

    .p-slider-handle:hover {
        border: 3px solid var(--color-secondary-main) !important;
        background-color: var(--color-neutral-0) !important;
    }
}

.p-datatable .p-datatable-tbody > tr.search-restricted-row:hover {
    background-color: var(--color-neutral-0) !important;
}

.progress-bar {
    .p-slider,
    .p-slider-range {
        background: transparent;
    }

    .p-slider.p-slider-horizontal {
        width: 100%;
        height: 20px;
        cursor: pointer;
    }
}

.volume-bar {
    .p-slider.p-slider-horizontal {
        height: 8px;
        width: inherit;
        border-radius: 5px;
    }

    .p-slider {
        background: var(--color-neutral-50);
    }

    .p-slider-range {
        background: var(--color-secondary-main);
        border-radius: 5px;
    }
}

.volume-bar,
.progress-bar {
    .p-slider-handle {
        border: unset !important;
        height: 20px;
        width: 20px;
        background: var(--color-secondary-main);
        content: url("/assets/icons/player-timeline-handle.svg");
        z-index: 999;
    }

    .p-slider-handle:focus {
        box-shadow: 0 0 4px 4px var(--color-secondary-main);
    }

    .p-slider-handle:active {
        box-shadow: 0 0 4px 4px var(--color-secondary-main);
    }

    .p-slider-handle:hover {
        background: var(--color-secondary-main) !important;
    }
}

.volume-bar-hidden {
    .p-slider-handle {
        width: 0 !important;
    }
}

.ngx-charts {
    background-color: var(--color-neutral-10);
    height: 100%;
    float: unset !important;
}

.ngx-charts .area {
    .progress-bar {
        .p-slider,
        .p-slider-range {
            background: transparent;
        }

        .p-slider.p-slider-horizontal {
            width: 100%;
        }

        .p-slider-handle {
            border: unset !important;
            height: 20px;
            width: 20px;
            background: transparent;
            content: url("/assets/icons/player-timeline-handle.svg");
        }

        .p-slider-handle:focus {
            box-shadow: 0 0 4px 4px var(--color-secondary-main);
        }

        .p-slider-handle:active {
            box-shadow: 0 0 4px 4px var(--color-secondary-main);
        }
    }
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    color: var(--color-neutral-60);
}

.p-menu .p-menuitem-link:hover {
    background-color: var(--color-neutral-5) !important;
}

/**Animations*/

@keyframes increaseSize {
    0% {
        width: 0;
    }
    100% {
        width: 50px;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes enterFromBottom {
    0% {
        transform: translateY(50px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes p-progress-spinner-color {
    0% {
        stroke: var(--color-primary-main) !important;
    }

    20% {
        stroke: var(--color-primary-main) !important;
    }

    40% {
        stroke: var(--color-primary-main) !important;
    }

    60% {
        stroke: var(--color-primary-main) !important;
    }

    80% {
        stroke: var(--color-primary-main) !important;
    }

    100% {
        stroke: var(--color-primary-main) !important;
    }
}

@keyframes custom-progress-spinner-dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

.search-overlay {
    height: var(--search-overlay-height);
    width: var(--search-overlay-width);
    top: calc(50% - 200px);
    left: calc(50% - 30px);
    position: absolute;
    z-index: 1001;
    background: white;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    padding: var(--search-overlay-padding);

    .search-text {
        font-weight: 700;
        color: var(--color-neutral-70);
        letter-spacing: 0.2px;
        margin-top: 5px;
        margin-bottom: 18px;
    }

    .cancel-button {
        background-color: transparent;
        border: unset;
        color: var(--font-color-dark);
    }

    .cancel-button:hover {
        background-color: var(--color-neutral-15) !important;
        color: var(--font-color-dark) !important;
    }
}

.player-controls {
    background-color: var(--player-background-color);
    width: 100%;
    padding: 15px 25px 10px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    align-items: center;

    .progress-bar {
        background-color: var(--color-neutral-50);
        height: 8px;
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;

        .progress-bar-handle {
            height: 20px;
            width: 20px;
            position: absolute;
        }
    }

    .player-main-controls-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 17px;

        .left-controls,
        .right-controls {
            display: flex;
            position: relative;

            .player-main-control-button {
                background-color: transparent;
            }

            .player-main-control-button:hover {
                background-color: rgba(34, 33, 29, 0.4);
            }
        }

        .left-controls {
            padding-right: 3px;

            .volume-control {
                position: relative;
                display: flex;
                align-items: center;

                .volume-bar-container {
                    height: 40px;
                    width: 0;
                    margin: 0;
                    display: flex;
                    align-items: center;

                    .volume-bar {
                        width: inherit;
                    }
                }
            }
        }

        .middle-controls {
            display: flex;

            .player-secondary-control-button {
                background-color: var(--color-neutral-70);
                margin-right: 2px;
            }

            .player-secondary-control-button:last-child {
                margin-right: 0;
            }

            .player-secondary-control-button:hover {
                background-color: var(--color-neutral-80);
            }

            .player-label {
                color: var(--color-neutral-0);
                background-color: var(--color-neutral-70);
                font-weight: 700;
                font-size: 16px;
                letter-spacing: 0;
                width: 100px;
                height: 40px;
                display: flex;
                flex-direction: column;
                padding-left: 8px;
                justify-content: center;
                margin-right: 2px;
            }
        }
    }
}

/**
Accessibility
*/

.hit-list-title,
.item-column,
.name-column {
    .accessibility-link {
        color: unset !important;
        text-decoration: none;
        pointer-events: none;
    }
}

/**Offscreen for Accessibility*/

.h-offscreen {
    clip: rect(0, 0, 0, 0);
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
